import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import Header from '../containers/Header';
import MainWhitelist from '../containers/MainWhitelist';
import Footer from '../containers/Footer';
import PageContainer from '../containers/PageContainer';
import ThemeProvider from '../containers/ThemeProvider';
import WalletProvider from '../containers/WalletProvider';

import '../sass/basic.scss';

const IndexPage: FC = () => {
  return (
    <ThemeProvider>
      <WalletProvider>
        <PageContainer>
          <Header />
          <MainWhitelist />
          <Footer />
        </PageContainer>
        <ToastContainer newestOnTop limit={1} />
      </WalletProvider>
    </ThemeProvider>
  );
};

export default IndexPage;
