import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import DonateBoard from '../DonateBoard';
import Banner from '../Banner';
import SEO from '../../components/SEO';

const MainWhitelist: FC = () => {
  const {
    allStrapiWhitelistMintBlock: {
      nodes: [data],
    },
  } = useStaticQuery(
    graphql`
      query MintDescriptionBlockWhiteListQuery {
        allStrapiWhitelistMintBlock {
          nodes {
            main_short_text
            main_title
          }
        }
      }
    `,
  );
  return (
    <main>
      <SEO />
      <Banner active textData={data} whitelist={true} />
      {/* <DonateBoard active /> */}
    </main>
  );
};

export default MainWhitelist;
